<template>
  <block
    component-name="block-media"
    ref="tpl_root"
    :px="false"
    body-class="overflow-hidden"
  >
    <template v-slot:default="blockData">
      <div class="overflow-hidden relative top-0 left-0 w-full">
        <div
          class="overflow-hidden w-full bg-black"
          v-surfer="{
            observers: {
              init:
                $attrs.data.body.asset.variant === 'static'
                  ? { class: 'scale-[110%] opacity-70 origin-top' }
                  : false,
              visible:
                '!scale-100 !opacity-100 transition-fade duration-[4000ms] ease-cubic-out',
              offscreenTop: '!scale-100 !opacity-100',
            },
          }"
        >
          <media
            ref="tpl_media"
            tag="div"
            class="block w-full"
            v-bind="$attrs.data.body.asset"
            :sizes="{
              base: 600,
              phablet: 1020,
              tablet: 1272,
              laptop: 1512,
              desktop: 1920,
              extdesktop: 2560,
            }"
            :loading="blockData?.loading || 'auto'"
          />
        </div>

        <!-- RESOURCE SVG -->
        <wrapper-reveal
          v-if="blockData?.config?.useResourceBadge"
          class="absolute inset-0 w-full h-full p-edge flex justify-end items-start"
          reveal="fade-from-bottom"
          :delay="200"
          inert
        >
          <svg-resource-badge />
        </wrapper-reveal>
        <!-- end RESOURCE SVG -->
      </div>
    </template>
  </block>
</template>

<script setup>
const attrs = useAttrs();
const tpl_root = templateRef("tpl_root");
const tpl_media = templateRef("tpl_media");
const { height: elH } = useElementSize(tpl_root);
const { height: windowH } = useWindowSize();

useSafeMountedEl([tpl_root, tpl_media], () => {
  gsap.from(tpl_media.value.$el, {
    opacity: 0.2,
    ease: "sine.out",
    scrollTrigger: {
      trigger: tpl_root.value.$el,
      start: "top 95%",
      end: "clamp(top top)",
      scrub: true,
    },
  });

  gsap.fromTo(
    tpl_media.value.$el,
    {
      yPercent: attrs.data.body.asset.variant === "static" ? -25 : -17,
    },
    {
      yPercent: 0,
      ease: "none",
      scrollTrigger: {
        trigger: tpl_root.value.$el,
        start: "top bottom",
        end: "bottom bottom",
        scrub: true,
      },
    }
  );

  gsap.to(tpl_media.value.$el.querySelector("img"), {
    yPercent: attrs.data.body.asset.variant === "static" ? 25 : 17,
    ease: "none",
    scrollTrigger: {
      trigger: tpl_root.value.$el,
      start: "top top",
      end: "bottom top",
      scrub: true,
    },
  });
});
</script>
